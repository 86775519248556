import { BrowserModule, Meta } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerModule } from '@vg-constellation/angular-13/spinner';
import { ModalDialogModule } from '@vg-constellation/angular-13/modal-dialog';
import { BannerModule } from '@vg-constellation/angular-13/banner';
import { LinkModule } from '@vg-constellation/angular-13/link';
import { RadioModule } from '@vg-constellation/angular-13/radio';
import { SelectModule } from '@vg-constellation/angular-13/select';
import { InputModule } from '@vg-constellation/angular-13/input';
import { WindowRef } from './services/window-ref/window-ref.service';
import {
  ENVIRONMENT_CONFIG as COMPONENT_LIB_ENVIRONMENT_CONFIG,
  Environment as ComponentLibEnvironment,
  PageLayoutModule,
  SecondaryNavigationModule,
  CustomizeViewModule,
  AdobeLaunchService,
  AdobeLaunchModule,
} from '@vanguard/secure-site-components-lib';

import { ContentDirective } from './directives/content/content.directive';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ContentAsAService } from './services/content-as-a-service/content-as-a-service.service';
import { PortfolioDataService } from './services/portfolio-data-service/portfolio-data.service';
import { AlertsHelperService } from './services/alerts-helper/alerts-helper.service';
import { LoggerService } from './services/logger/logger.service';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ConstellationIconComponent } from './components/constellation-icon/constellation-icon.component';
import { AlertContainerComponent } from './components/alert-container/alert-container.component';
import { RoundingDisclaimerComponent } from './components/rounding-disclaimer/rounding-disclaimer.component';
import { TargetMixDisclaimerComponent } from './components/target-mix-disclaimer/target-mix-disclaimer.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { StockCardComponent } from './components/stock-card/stock-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { PortfolioCardsService } from './services/portfolio-cards/portfolio-cards.service';
import { ChartLegendComponent } from './components/chart-legend/chart-legend.component';
import { ChartLegendLineComponent } from './components/chart-legend-line/chart-legend-line.component';
import { BondCardComponent } from './components/bond-card/bond-card.component';
import { CostCardComponent } from './components/cost-card/cost-card.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { PercentFormattingPipe } from './pipes/percent-formatting/percent-formatting.pipe';
import { DateConverterPipe } from './pipes/date-converter/date-converter.pipe';
import { AssetCardComponent } from './components/asset-card/asset-card.component';
import { AssetMixDashboardComponent } from './components/asset-mix-dashboard/asset-mix-dashboard.component';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { SetTargetAssetMixModalComponent } from './components/set-target-asset-mix-modal/set-target-asset-mix-modal.component';
import { DonutChartComponentComponent } from './components/donut-chart-component/donut-chart-component.component';
import { AssetAllocationComponent } from './pages/asset-allocation/asset-allocation.component';
import { AlertNotificationsComponent } from './components/alert-notifications/alert-notifications.component';
import { TargetMixSuccessBannerComponent } from './components/target-mix-success-banner/target-mix-success-banner.component';
import { RiskReturnAnalysisComponent } from './components/risk-return-analysis/risk-return-analysis.component';
import { CustomAccordionComponent } from './components/custom-accordion/custom-accordion.component';
import { ExportToCsvComponent } from './components/export-to-csv/export-to-csv.component';
import { AssetAllocationHoldingsComponent } from './components/asset-allocation-holdings/asset-allocation-holdings.component';
import { HoldingsTableComponent } from './components/holdings-table/holdings-table.component';
import { VuiButtonModule } from '@vanguard/vui/button';

import { StockDashboardComponent } from './components/stock-dashboard/stock-dashboard.component';
import { StockAnalysisComponent } from './pages/stock-analysis/stock-analysis.component';
import { StockAnalysisTabTableComponent } from './components/stock-analysis-tab-table/stock-analysis-tab-table.component';
import { MarketCapTableComponent } from './components/stock-analysis-tab-table/market-cap-table/market-cap-table.component';
import { InvestmentStyleTableComponent } from './components/stock-analysis-tab-table/investment-style-table/investment-style-table.component';
import { InternationalRegionsTableComponent } from './components/stock-analysis-tab-table/international-regions-table/international-regions-table.component';
import { IndustrySectorsTableComponent } from './components/stock-analysis-tab-table/industry-sectors-table/industry-sectors-table.component';
import { TableViewControlsComponent } from './components/table-view-controller/table-view-controls.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InternationalDomesticHoldingsComponent } from './components/international-domestic-holdings/international-domestic-holdings.component';
import { BondAnalysisComponent } from './pages/bond-analysis/bond-analysis.component';
import { BondDashboardComponent } from './components/bond-dashboard/bond-dashboard.component';
import { BondAnalysisTabTableComponent } from './components/bond-analysis-tab-table/bond-analysis-tab-table.component';
import { CreditQualityTableComponent } from './components/bond-analysis-tab-table/credit-quality-table/credit-quality-table.component';
import { EffectiveMaturityTableComponent } from './components/bond-analysis-tab-table/effective-maturity-table/effective-maturity-table.component';
import { NineBoxComponent } from './components/bond-analysis-tab-table/nine-box/nine-box.component';
import { CostsRiskComponent } from './pages/costs-risk/costs-risk.component';
import { CostRiskTabTableComponent } from './components/cost-risk-tab-table/cost-risk-tab-table.component';
import { MutualFundTableComponent } from './components/cost-risk-tab-table/mutual-fund-table/mutual-fund-table.component';
import { TaxEfficiencyTableComponent } from './components/cost-risk-tab-table/tax-efficiency-table/tax-efficiency-table.component';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { IconModule } from '@vg-constellation/angular-13/icon';
import { AccordionModule } from '@vg-constellation/angular-13/accordion';
import { QuestionnairePageComponent } from './components/questionnaire-page/questionnaire-page.component';
import { WantHelpBannerComponent } from './components/want-help-banner/want-help-banner.component';
import { SuggestedTargetAssetMixComponent } from './pages/suggested-target-asset-mix/suggested-target-asset-mix.component';
import { TargetAllocationCustomViewComponent } from './pages/target-allocation-custom-view/target-allocation-custom-view.component';
import { SetTargetMixManuallyComponent } from './components/set-target-mix-manually/set-target-mix-manually.component';
import { TargetAssetAllocationSliderInputComponent } from './components/target-asset-allocation-slider-input/target-asset-allocation-slider-input.component';
import { TargetMixAboutComponent } from './pages/target-mix-about/target-mix-about.component';
import { AdviceBannerComponent } from './components/advice-banner/advice-banner.component';

import { TesterToolComponent } from './components/tester-tool-components/tester-tool/tester-tool.component';
import { HypotheticalOverviewComponent } from './components/tester-tool-components/hypothetical-overview/hypothetical-overview.component';
import { TesterToolGuard } from './components/tester-tool-components/tester-tool-guard/tester-tool-guard';
import { AccountGroupAssetsComponent } from './components/tester-tool-components/account-group-assets/account-group-assets.component';
import { HypotheticalAssetAllocationComponent } from './components/tester-tool-components/hypothetical-asset-allocation/hypothetical-asset-allocation.component';
import { HypotheticalAssetAllocationHoldingsComponent } from './components/tester-tool-components/hypothetical-asset-allocation/hypothetical-asset-allocation-holdings/hypothetical-asset-allocation-holdings.component';
import { HypotheticalBondAnalysisComponent } from './components/tester-tool-components/hypothetical-bond-analysis/hypothetical-bond-analysis.component';
import { HypotheticalCreditQualityTableComponent } from './components/tester-tool-components/hypothetical-bond-analysis/tabs/hypothetical-credit-quality-table/hypothetical-credit-quality-table.component';
import { HypotheticalEffectiveMaturityTableComponent } from './components/tester-tool-components/hypothetical-bond-analysis/tabs/hypothetical-effective-maturity-table/hypothetical-effective-maturity-table.component';
import { HypotheticalNineBoxComponent } from './components/tester-tool-components/hypothetical-bond-analysis/tabs/hypothetical-nine-box/hypothetical-nine-box.component';
import { HypotheticalBondAnalysisTabTableComponent } from './components/tester-tool-components/hypothetical-bond-analysis/tabs/hypothetical-bond-analysis-tab-table.component';
import { HypotheticalStockAnalysisComponent } from './components/tester-tool-components/hypothetical-stock-analysis/hypothetical-stock-analysis.component';
import { HypotheticalStockAnalysisTabTableComponent } from './components/tester-tool-components/hypothetical-stock-analysis/tabs/hypothetical-stock-analysis-tab-table.component';
import { HypotheticalMarketCapTableComponent } from './components/tester-tool-components/hypothetical-stock-analysis/tabs/hypothetical-market-cap-table/hypothetical-market-cap-table.component';
import { HypotheticalInvestmentStyleTableComponent } from './components/tester-tool-components/hypothetical-stock-analysis/tabs/hypothetical-investment-style-table/hypothetical-investment-style-table.component';
import { HypotheticalInternationalRegionsTableComponent } from './components/tester-tool-components/hypothetical-stock-analysis/tabs/hypothetical-international-regions-table/hypothetical-international-regions-table.component';
import { HypotheticalIndustrySectorsTableComponent } from './components/tester-tool-components/hypothetical-stock-analysis/tabs/hypothetical-industry-sectors-table/hypothetical-industry-sectors-table.component';
import { HypotheticalCostsRiskComponent } from './components/tester-tool-components/hypothetical-cost-risk/hypothetical-costs-risk.component';
import { HypotheticalTaxEfficiencyTableComponent } from './components/tester-tool-components/hypothetical-cost-risk/tabs/hypothetical-tax-efficiency-table/hypothetical-tax-efficiency-table.component';
import { HypotheticalMutualFundTableComponent } from './components/tester-tool-components/hypothetical-cost-risk/tabs/hypothetical-mutual-fund-table/hypothetical-mutual-fund-table.component';
import { HypotheticalCostRiskTabTableComponent } from './components/tester-tool-components/hypothetical-cost-risk/tabs/hypothetical-cost-risk-tab-table.component';
import { PrintViewComponent } from './pages/print-view/print-view.component';
import { AdobeLaunchEnvironment } from '@vanguard/secure-site-components-lib/lib/models/adobe-launch.model';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { SecureSiteHeaderContainerComponent } from './components/secure-site-header-container/secure-site-header-container.component';
import { environment } from 'src/environments/environment';
import { ButtonModule } from '@vg-constellation/angular-13/button';
import { FailureCardComponent } from './components/failure-card/failure-card.component';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { DrawerModule } from '@vanguard/pfx-components';
import { DomainResolver } from './utility-classes/domain-resolver';
import { AdobeAnalyticsService } from './services/adobe-analytics/adobe-analytics.service';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DrawerModule as ConstellationDrawerModule } from '@vg-constellation/angular-13/drawer';
import { CustomReuseStrategy } from './routerReuseStrategy';
import { InvestmentCostsDisclaimerAccordionComponent } from './components/investment-costs-disclaimer-accordion/investment-costs-disclaimer-accordion.component';

@NgModule({
  declarations: [
    ContentDirective,
    AppComponent,
    // Main landing page components
    MainPageComponent,
    AssetCardComponent,
    StockCardComponent,
    BondCardComponent,
    CostCardComponent,
    FailureCardComponent,

    // Stock analysis page components
    StockAnalysisComponent,
    StockAnalysisTabTableComponent,
    MarketCapTableComponent,
    InvestmentStyleTableComponent,
    IndustrySectorsTableComponent,
    InternationalRegionsTableComponent,
    InternationalDomesticHoldingsComponent,

    // Bond analysis page components
    BondAnalysisComponent,
    BondDashboardComponent,
    BondAnalysisTabTableComponent,
    CreditQualityTableComponent,
    EffectiveMaturityTableComponent,
    NineBoxComponent,

    // Costs page components
    CostsRiskComponent,
    CostRiskTabTableComponent,
    MutualFundTableComponent,
    DisclaimerComponent,
    TaxEfficiencyTableComponent,

    // Target asset mix page components
    WantHelpBannerComponent,

    // Target asset mix questionnaire page components
    QuestionnaireComponent,
    QuestionnairePageComponent,

    // Suggested target asset mix page components
    SuggestedTargetAssetMixComponent,

    // Target asset mix custom view page components
    TargetAllocationCustomViewComponent,
    SetTargetMixManuallyComponent,
    TargetAssetAllocationSliderInputComponent,

    // Target asset mix about page components
    TargetMixAboutComponent,
    AdviceBannerComponent,

    // Tester tool components
    TesterToolComponent,
    HypotheticalOverviewComponent,
    AccountGroupAssetsComponent,
    HypotheticalAssetAllocationComponent,
    HypotheticalAssetAllocationHoldingsComponent,
    HypotheticalBondAnalysisComponent,
    HypotheticalCreditQualityTableComponent,
    HypotheticalEffectiveMaturityTableComponent,
    HypotheticalNineBoxComponent,
    HypotheticalBondAnalysisTabTableComponent,
    HypotheticalStockAnalysisComponent,
    HypotheticalStockAnalysisTabTableComponent,
    HypotheticalMarketCapTableComponent,
    HypotheticalInvestmentStyleTableComponent,
    HypotheticalInternationalRegionsTableComponent,
    HypotheticalIndustrySectorsTableComponent,
    HypotheticalCostsRiskComponent,
    HypotheticalCostRiskTabTableComponent,
    HypotheticalTaxEfficiencyTableComponent,
    HypotheticalMutualFundTableComponent,

    // Print view components
    PrintViewComponent,

    // Header components
    AppHeaderComponent,
    SecureSiteHeaderContainerComponent,

    ConstellationIconComponent,
    AlertContainerComponent,
    RoundingDisclaimerComponent,
    TargetMixDisclaimerComponent,
    ChartLegendComponent,
    ChartLegendLineComponent,
    BarChartComponent,
    DateConverterPipe,
    AssetMixDashboardComponent,
    StackedBarChartComponent,
    SetTargetAssetMixModalComponent,
    DonutChartComponentComponent,
    AlertNotificationsComponent,
    TargetMixSuccessBannerComponent,
    RiskReturnAnalysisComponent,
    CustomAccordionComponent,
    ExportToCsvComponent,
    AssetAllocationComponent,
    AssetAllocationHoldingsComponent,
    HoldingsTableComponent,
    PercentFormattingPipe,
    StockDashboardComponent,
    TableViewControlsComponent,
    InvestmentCostsDisclaimerAccordionComponent,
  ],
  imports: [
    AppRoutingModule,
    AdobeLaunchModule,
    BrowserModule,
    ConstellationDrawerModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    SharedPipesModule,

    // Material modules
    MatCardModule,
    MatTabsModule,
    BrowserAnimationsModule,

    // c11n modules
    IconModule,
    ModalDialogModule.forRoot(),
    SpinnerModule,
    LinkModule,
    BannerModule,
    RadioModule,
    SelectModule,
    InputModule,
    ButtonModule,
    AccordionModule,

    // vui modules
    VuiButtonModule,

    // ss-lib modules
    PageLayoutModule,
    SecondaryNavigationModule,
    CustomizeViewModule,

    // pfx-components modules
    DrawerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    {
      provide: COMPONENT_LIB_ENVIRONMENT_CONFIG,
      useValue: {
        isProd: environment.ANGULAR_ENV === 'production',
        isPerf: environment.ANGULAR_ENV === 'eng',
        isInternal: environment.isExternal === 'false',
        appName: 'PortfolioWatch',
      } as unknown as ComponentLibEnvironment,
    },
    ContentAsAService,
    AlertsHelperService,
    LoggerService,
    PortfolioDataService,
    PortfolioCardsService,
    AdobeAnalyticsService,
    PercentFormattingPipe,
    TesterToolGuard,
    WindowRef,
    DomainResolver,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: function (
        http: HttpClient,
        meta: Meta,
        windowRef: WindowRef,
        adobe: AdobeLaunchService,
      ) {
        const $window: Window = windowRef.nativeWindow;
        return async () => {
          await http
            .get(`${environment.CONSUMER_API}adobe`, { withCredentials: true })
            .toPromise()
            .then((data: any) => {
              const spoid = data.sPOID;
              meta.updateTag({ name: 'DCSext.poid', content: data.poid });
              meta.updateTag({ name: 'DCSext.sPoid', content: spoid }); // Used for Floodlight
              meta.updateTag({ name: 'WT.seg_6', content: data.crewFlag });
              $window.spoid = spoid; // Medallia
              adobe.initialize(
                { pageTemplate: 'portfolio-analysis' },
                environment.deployment as AdobeLaunchEnvironment,
              );
              // 'unhide' app <body> now that page is sure to be displayed.
              $window.document.body.hidden = false;
            })
            .catch(() => {
              if (environment.isExternal === 'true') {
                $window.document.location.href = environment.LOGON_URL;
                return Promise.reject();
              } else {
                $window.document.body.hidden = false;
                return true;
              }
            });
        };
      },
      deps: [HttpClient, Meta, WindowRef, AdobeLaunchService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
