<div class="tester-background modernized">
    <div class="tester-tool-view" #content="pwContent" pwContent *ngIf="hasLoadedChartData">
        <h2 class="header"
            [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerHeader'] + selectedAccountGroup.GroupName">
        </h2>
        <h3 [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerPurchase']"></h3>
        <div class="formRowAdditions" *ngFor="let addition of additionsFormArray.controls; let i = index"
            id="formRowAdditions-{{i}}">
            <ng-container [formGroup]="addition">
                <div class="ticker-wrapper">  
                    <div>
                        <button class="close-addition" (click)="deleteAdditionLine(i)" [style]="'secondary'" aria-label="clickable remove line" >
                            <span class="icon icon-plus" id="remove-button" ></span>
                        </button>
                    </div>   
                    <div class="ticker">
                        <div >
                            <label class="c11n-label c11n-label--large" for="ticker-{{i}}"
                                [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerLabel']"></label>
                            <div class="c11n-input c11n-input--medium ">
                                <input type="text" id="ticker-{{i}}" name="input-text" formControlName="tickerControl" (focus)="validateTicker(i, true)"
                                    (blur)="validateTicker(i)" (input)="buttonCheck()" class="c11n-input__input"
                                    aria-describedby="ticker-{{i}}-description" aria-required="true">
                                <div class="c11n-input__focus-element"></div>
                            </div>
                            <div class="c11n-error error tickerError" id="ticker-error-{{i}}">
                                <span class="c11n-error__icon">
                                    <svg role="img" class="c11n-error-small" aria-labelledby="title" >
                                        <title>error icon</title>
                                        <use xlink:href="#error-small"></use>
                                    </svg>
                                </span>
                                <span id="ticker-{{i}}-description" role="alert" class="c11n-error__content" aria-label="error"
                                    [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerErrorSymbol']"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dropdown">
                    <label class="dropdown-label__content" for="ticker-dropdown-{{i}}"
                        [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerDropdownLabel']"></label>
                    <c11n-select [labelText]="''" [size]="'medium'" [hintText]="''"
                        (stateChange)="buttonCheck();" >
                        <select c11nSelectInput id="ticker-dropdown-{{i}}" formControlName="accountControl" aria-describedby="ticker-dropdown-{{i}}-description"
                            (blur)="validateAccountGroup(i)" (onChange)="validateAccountGroup(i)" (focus)="validateAccountGroup(i, true)"
                            class="c11n-select__box__select" aria-required="true" >
                            <option value="0" disabled selected>Select one</option>
                            <option *ngFor="let account of accounts" [value]="account.accountId">
                                {{ account.accountName }}
                            </option>
                        </select>                    
                    </c11n-select>
                    <div id="ticker-account-error-{{i}}" class="c11n-error error tickerError"
                        >
                        <span class="c11n-error__icon">
                            <svg role="img" class="c11n-error-small" aria-labelledby="title">
                                <title>error icon</title>
                                <use xlink:href="#error-small"></use>
                            </svg>
                        </span>
                        <span id="ticker-{{i}}-description" role="alert" class="c11n-error__content" aria-label="error" 
                        [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerErrorAccount']"></span>
                    </div>
                </div>
                <div class="value">
                    <div>
                        <label class="c11n-label c11n-label--large" for="ticker-value-{{i}}"
                            [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerValueLabel']"></label>
                        <div class="c11n-input c11n-input--medium ">
                            <input type="number" id="ticker-value-{{i}}" name="input-text"
                                formControlName="valueControl" (blur)="validateNumber(i)" (input)="buttonCheck()"  (focus)="validateNumber(i, true)"
                                class="c11n-input__input" aria-describedby="ticker-value-{{i}}-description" aria-required="true">
                            <div class="c11n-input__focus-element"></div>
                        </div>
                        <div class="c11n-error error tickerError" id="ticker-value-error-{{i}}">
                            <span class="c11n-error__icon">
                                <svg role="img" class="c11n-error-small" aria-labelledby="title" >
                                    <title>error icon</title>
                                    <use xlink:href="#error-small"></use>
                                </svg>
                            </span>
                            <span id="ticker-value-{{i}}-description" role="alert" class="c11n-error__content" aria-label="error"
                                [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerErrorValue']"></span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="button-wrapper">
            <span>
                <vui-button [style]="'secondary'" (click)="addAdditionLine(); buttonCheck()">
                    {{content['PortfolioTester_HypoHoldingsForm_AddALine']}}</vui-button>
            </span>
            <span class="button-analyze">
                <vui-button id="button-analyze-top" [style]="'primary'" (click)="analyzeHypothetical()"
                    [disabled]="!buttonEnabled">{{content['PortfolioTester_HypoHoldingsForm_Analyze']}}</vui-button>
            </span>
        </div>
        <ng-container *ngIf="hasLoadedChartData">
            <h2 class="header" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_HoldingsHeader']"></h2>
            <h3 [innerHTML]="content['PortfolioTester_HypoHoldingsForm_HoldingsSubheader']"></h3>
            <table class="accountTable" *ngFor="let acct of accountsWithHoldings; let i = index">
                <caption class="accountHeader">
                    <h4 class="accountName">{{acct.accountName}}</h4>
                </caption>
                <tr class="formRowChanges headers">
                    <th scope="col" class="name" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_Name']"></th>
                    <th scope="col" class="percent" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_Value']"></th>
                    <th scope="col" class="balance"></th>
                    <th scope="col" class="change" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_ChangeValue']"></th>
                </tr>
                <ng-container *ngFor="let holdingGroup of acct.formGroups.controls; let j = index">
                    <ng-container [formGroup]="holdingGroup">
                        <tr class="formRowChanges">
                            <th scope="row" class="name content">{{holdingGroup.get("fundNameControl").value}}</th>
                            <td class="percent content">{{holdingGroup.get("percentageControl").value |
                                percentFormatted}}</td>
                            <td class="balance content">{{holdingGroup.get("fundBalanceControl").value |
                                currency}}</td>
                            <td class="change content">

                                <input class="holdings input" (focus)="holdingsErrorStyle(i, j, holdingGroup)"   
                                    (input)="buttonCheck();" id="holdings-value-change-{{i}}{{j}}" type="number"
                                    formControlName="balanceChangeControl" [attr.aria-label]="content['PortfolioTester_HypoHoldingsForm_ChangeValue']" 
                                    aria-describedby="number-error-{{i}}{{j}}" />

                                <div class="error" id="number-error-{{i}}{{j}}">
                                    <span class="c11n-error__icon">
                                        <svg role="img" class="c11n-error-small" >
                                            <use xlink:href="#error-small"></use>
                                        </svg>
                                    </span>
                                    <span role="alert" aria-label="error"
                                    [innerHTML]="content['PortfolioTester_HypoHoldingsForm_HoldingsError']"></span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
            <div class="button-wrapper">
                <div *ngIf="hypotheticalCallError" class="error callError">
                    <span class="alert-icon"></span>
                    <span [innerHTML]="content['PortfolioTester_HypoHoldingsForm_CallError']"></span>
                </div>
                <span>
                    <vui-button [style]="'secondary'" (click)="ngOnInit()">
                        {{content['PortfolioTester_HypoHoldingsForm_Clear']}}</vui-button>
                </span>
                <span class="button-analyze">
                    <vui-button id="button-analyze-bottom" [style]="'primary'"
                        (click)="analyzeHypothetical()" [disabled]="!buttonEnabled">
                        {{content['PortfolioTester_HypoHoldingsForm_Analyze']}}</vui-button>
                </span>
            </div>
        </ng-container>
    </div>
</div>